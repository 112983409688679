<template>
    <v-app class="layout-start">
        <AppVSystemBar/>

        <v-content>
            <router-view/>
        </v-content>
    </v-app>
</template>

<script>
    import {
        VApp,

        VContent,
    } from 'vuetify/lib'
    import AppVSystemBar from '@/components/vuetify/SystemBar'

    export default {
        name: 'start',

        components: {
            VApp,

            VContent,

            AppVSystemBar,
        },

        data() {
            return {}
        },
    }
</script>

<style lang="scss" scoped>
    .layout-start {
        background-color:    #0d0f0d;
        background-image:    url("../assets/images/background/auth.jpg");
        background-position: center;
        background-repeat:   no-repeat;
        background-size:     cover;
    }
</style>
